import Configurazione from "../config/configurazione";
import http from "../http-common";

let configurazione = Configurazione();

class ExampleService {

  metadati(codtrel) {
    return http.get("/" + codtrel + "/metadati");
  }

  alberatura(codtrel) {
    return http.get("/" + codtrel + "/alberatura");
  }

  getRelazione(codtrel, anno, sezione) {
    return http.get(`/${codtrel}/relazioni/${anno}/${sezione}`);
  }

  getVariabili(codtrel, anno, sezione, riga) {
    return http.get(`/${codtrel}/variabili/${anno}/${sezione}/${riga}`);
  }

  getExportVariabili(codtrel, anno, sezione, riga) {
    return http.get(`/${codtrel}/variabiliExport/${anno}/${sezione}/${riga}`);
  }

  getVariabiliDettagliate(codtrel, anno, sezione, riga, codVariabile, annoCorrelato) {
    return http.get(`/${codtrel}/variabili/${anno}/${sezione}/${riga}/${codVariabile}/${annoCorrelato}`);
  }

  getBreadcrumb(codtrel, path) {
    return http.post(`/${codtrel}/breadcrumb`, {
      path: path
    });
  }

  getQuadroComplessivoDegliIndicatori(codtrel, anno) {
    return http.get(`/${codtrel}/` + configurazione.pathRadice + `/` + anno);
  }


}

export default new ExampleService();