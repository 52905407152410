import React, { useEffect, useState } from "react"
import exampleService from "../services/example-service";
import Spinner from "../components/Spinner";
import RigaTabellaDato from "../components/RigaTabellaDato";
import getCodtrel from "../components/utils";



export default function VariabiliDettagliate(props) {


    useEffect(() => {
        fetchData();
        document.title = "Relazione sullo Stato dell'Ambiente - Regione Liguria";
    }, []);

    const [formSchema, setFormSchema] = useState([]);
    const [loading, setLoading] = useState(true);

    const [errore, setErrore] = useState("");


    const JSONToCSVConvertor = () => {


        //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
        var arrData =
            typeof formSchema.lista !== "object" ? JSON.parse(formSchema.lista) : formSchema.lista;

        var CSV = "";



        //1st loop is to extract each row
        for (var i = 0; i < arrData.length; i++) {
            var row = "";

            //2nd loop will extract each column and convert it in string comma-seprated
            for (var index in arrData[i]) {
                row += '"' + arrData[i][index] + '",';
            }

            row.slice(0, row.length - 1);

            //add a line break after each row
            CSV += row + "\r\n";
        }

        if (CSV === "") {
            alert("Invalid data");
            return;
        }

        //Generate a file name
        var fileName = formSchema.annoCorrelato + "_";
        //this will remove the blank-spaces from the title and replace it with an underscore
        fileName += formSchema.nomeVariabile.replace(/ /g, "_");

        //Initialize file format you want csv or xls
        var uri = "data:text/csv;charset=utf-8," + escape(CSV);

        // Now the little tricky part.
        // you can use either>> window.open(uri);
        // but this will not work in some browsers
        // or you will not get the correct file extension

        //this trick will generate a temp <a /> tag
        var link = document.createElement("a");
        link.href = uri;

        //set the visibility hidden so it will not effect on your web-layout
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";

        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const JSONToCSVConvertorDue = () => {
        var json = formSchema.lista;
        var fields = Object.keys(json[0])
        var replacer = function (key, value) { return value === null ? '' : value }
        var csv = json.map(function (row) {
            return fields.map(function (fieldName) {
                return JSON.stringify(row[fieldName], replacer)
            }).join(',')
        })
        csv.unshift(fields.join(',')) // add header column
        csv = csv.join('\r\n');
        var uri = "data:text/csv;charset=utf-8," + escape(csv);

        var link = document.createElement("a");
        link.href = uri;

        link.style = "visibility:hidden";

        var fileName = formSchema.annoCorrelato + "_";
        fileName += formSchema.nomeVariabile.replace(/ /g, "_");

        link.download = fileName + ".csv";

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    const fetchData = async () => {
        await exampleService.getVariabiliDettagliate(getCodtrel(), props.match.params.anno, props.match.params.sezione, props.match.params.riga, props.match.params.codVariabile, props.match.params.annoCorrelato)
            .then((response) => {
                setFormSchema(response.data);
                setLoading(false);
            })
            .catch((e) => {
                setErrore("Errore durante il recupero delle informazioni richieste");

                console.log(e);
                setFormSchema();
                console.error("ERRORE! ", e)
                setLoading(false);
                //window.location.replace("/errore/404");

            });
    };



    return <React.Fragment><>{loading ? (
        <Spinner />
    ) : (<>
        {errore !== "" ? <>
            <div class="container">
                <div class="alert alert-danger" role="alert">
                    {errore}
                </div>
            </div>
        </> : <>

            <div class="section section-image variabili">
                <div class="section-content">
                    <div class="container white-color ">
                        <div class="row">
                            <div class="col-12 pt-5 pb-5">

                            </div>
                        </div>
                        <div class="row">
                        </div>
                    </div>
                </div>
            </div><div class="container pt-5">
                <div class="row">
                    <div class="col">
                        <h1>{formSchema.annoCorrelato} - {formSchema.nomeVariabile}</h1>
                    </div>
                </div>

            </div><div class="container">

                <div class="row pt-5 pb-5">
                    <div class="col">

                        <a class="text-primary-blu"><u><strong onClick={JSONToCSVConvertorDue}>Scarica dati in formato CSV</strong></u></a>

                        <table class="table table-sm table-bordered table-striped">
                            <thead class="neutral-1-bg-a3">
                                <tr>
                                    <th scope="col">Comuni</th>
                                    <th scope="col">Valore ({formSchema.unitaDiMisura})</th>
                                </tr>
                            </thead>
                            <tbody>
                                {formSchema.lista.map((elemento, index) => {
                                    {
                                        return <RigaTabellaDato dataInterno={elemento} />;
                                    }
                                })}
                                <tr>

                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </>}



    </>)}</></React.Fragment>

}