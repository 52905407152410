import React from "react"
import { Link } from "react-router-dom";

import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Configurazione from "../config/configurazione";

import getCodtrel from "../components/utils"

function impostaVisibilita(url) {
    if (url == null) {
        return {
            display: 'none',
        };
    }
}



/*
      PER CREARE LA PARTE SOTTO DELLE TAB
*/
export default function SingolaTAB(JSONAmbito) {

    let configurazione = Configurazione();

    return (
        <><div class="row" style={impostaVisibilita(JSONAmbito.data.documento)}>
            <div class="col-8">
                <span class="pr-3"><strong></strong>{JSONAmbito.data.stato}</span>

                <span ><FontAwesomeIcon icon={faDownload} alt="" /> <a href={"/static/" + getCodtrel() + "/assets/statici/metadati/gestione/file/" + JSONAmbito.data.documento} target="_blank" rel="noreferrer">Autori della Relazione {JSONAmbito.data.anno} (PDF)</a></span>
            </div>
            <div class="col-4 text-right">
                <span class="pr-3"><strong></strong>{JSONAmbito.data.stato}</span>

                <span ><a href={"/static/" + getCodtrel() + "/assets/statici/metadati/gestione/file" + "/guidaLettura/GuidaLettura" + JSONAmbito.data.anno + ".pdf"} target="_blank" rel="noreferrer">Guida alla lettura (PDF)</a></span>
            </div>
        </div > <div class="row pt-3">
                <div class="col-12">
                    <h2>Aree tematiche</h2>
                    <div id="accordionDiv1" class="collapse-div" role="tablist">
                        {JSONAmbito.data.tematiche.map((ambito, index) => {
                            if (ambito.ordine != 99) {
                                return (
                                    <><div class="collapse-header" id={'heading' + ambito.path + JSONAmbito.data.anno}>
                                        <button data-toggle="collapse" data-target={'#' + ambito.path + JSONAmbito.data.anno} aria-expanded="false" aria-controls={ambito.path + JSONAmbito.data.anno}>
                                            <span class="text-dark">{ambito.label}</span>
                                        </button>
                                    </div><div id={ambito.path + JSONAmbito.data.anno} class="collapse " role="tabpanel" aria-labelledby={'heading' + ambito.path + JSONAmbito.data.anno} data-parent="#accordionDiv1">
                                            <div class="collapse-body">
                                                {
                                                    ambito.tematicheSpecifiche.map((tematica, index) => {
                                                        if (JSONAmbito.data.anno == 2006 || JSONAmbito.data.anno == 2007) {
                                                            return <div class="row">
                                                                <div class="col-12">

                                                                    <a href={"/static/" + getCodtrel() + "/assets/statici/metadati/gestione/file" + tematica.url.replace(".", "")} target="_blank">{tematica.label}</a>
                                                                </div>
                                                            </div>
                                                        } else {
                                                            return <div class="row">
                                                                <div class="col-12">

                                                                    <Link to={"/" + getCodtrel() + tematica.url}>{tematica.label}</Link>
                                                                </div>
                                                            </div>
                                                        }

                                                    })
                                                }
                                            </div>
                                        </div></>
                                )
                            } else {
                                return <div class="pl-4 borderetto" id={'heading' + ambito.path}>

                                    <h6><Link class="text-decoration-none font-weight-bold" to={"/" + getCodtrel() + ambito.tematicheSpecifiche[0].url}>{ambito.label}</Link></h6>
                                </div>

                            }



                        })}




                    </div>
                </div>
            </div></>
    )


}
