import sviluppo from "./sviluppo";
import testFo from "./test-priv";
import testPub from "./test-pub";
import testPubWso2 from "./test-pub-wso2";

import produzioneFo from "./produzione-priv";
import produzionePub from "./produzione-pub";
import produzioneWso2 from "./produzione-wso2";


export default function Configurazione() {
    return determinaConfigurazioneByUrl();
}

function determinaConfigurazioneByUrl() {
    const url = window.location.href;
    if (url.includes("SVILUPPO")) {
        return sviluppo;
    } else if (url.includes("https://relazioni-ambiente-pub-test.regione.liguria.it")) {
        return testPubWso2;
    } else if (url.includes("https://relazioni-ambiente-priv-test.regione.liguria.it")) {
        return testFo;
    } else if (url.includes("https://relazioni-ambiente-pub.regione.liguria.it")) {
        return produzionePub;
    } else if (url.includes("https://relazioniambiente.regione.liguria.it")) {
        return produzioneWso2;
    } else if (url.includes("https://relazioni-ambiente-priv.regione.liguria.it")) {
        return produzioneFo;
    } else if (url.includes("http://localhost:3000/")) {
        return sviluppo;
    } else {
        throw new Error("DEPLOY CONTEXT NON TROVATO. Verifica la configurazione dentro il file configurazione.jsx");
    }
}