import React, { useEffect, useState } from "react"
import SchedaIndicatore from "../components/SchedaIndicatore";
import exampleService from "../services/example-service";
import Spinner from "../components/Spinner";
import { HashLink } from 'react-router-hash-link';
import { faPrint, faUndo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import getCodtrel from "../components/utils";

function stampaConImmagini() {
    window.print();
}

function creaCodiceHtml(codice) {
    return { __html: codice };
}


export default function Relazione(props) {




    var urlPartenza = window.location.href;

    useEffect(() => {
        const intervallo = setInterval(() => {
            if (urlPartenza.includes("#")) {
                var idOggetto = urlPartenza.substring(urlPartenza.indexOf("#") + 1, urlPartenza.length);
                if (document.getElementById(idOggetto) != null) {
                    window.location.href = urlPartenza;
                    clearInterval(intervallo);
                }
            }
        }, 500);
        //return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        fetchData();

    }, []);

    const [formSchema, setFormSchema] = useState([]);
    const [loading, setLoading] = useState(true);

    const [errore, setErrore] = useState("");



    const fetchData = async () => {
        setLoading(true);
        await exampleService.getRelazione(getCodtrel(), props.match.params.anno, props.match.params.sezione)
            .then((response) => {
                setFormSchema(response.data);
                setLoading(false);
                if (formSchema.tematicaSpecifica != undefined)
                    document.title = formSchema.tematicaSpecifica + " - Relazione sullo Stato dell'Ambiente - Regione Liguria";
                else
                    document.title = "Relazione sullo Stato dell'Ambiente - Regione Liguria";
            })
            .catch((e) => {
                setErrore("Errore durante il recupero delle informazioni richieste");
                setLoading(false);
                console.error(e);
                setFormSchema();
                console.error("ERRORE! ", e)
                //window.location.replace("/errore/404");
            });
    };




    return <React.Fragment><>{loading ? (
        <Spinner />
    ) : (<>

        {errore !== "" ? <>
            <div class="container">
                <div class="alert alert-danger" role="alert">
                    {errore}
                </div>
            </div>
        </> : <>
            {formSchema.copertina.base != undefined
                ? (
                    <div class={"section container section-image pb-5 nascondi-nella-stampa"} style={{
                        backgroundImage: `url(data:image/png;base64,${formSchema.copertina.base})`,
                    }}>
                        <div class="section-content">
                            <div class="container white-color ">
                                <div class="row pt-5 pb-5">
                                    <div class="col-12">

                                    </div>
                                </div>
                                <div class="row">
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <br />
                )}


            <div class="container">
                <div class="row pt-5">
                    <div class="col-12 col-sm-8">
                        <h1>{/*values.tematica*/} {formSchema.tematicaSpecifica}</h1>
                    </div>
                    <div class="col-12 col-sm-2 text-right nascondi-nella-stampa">
                        <span className="btn btn-primary" onClick={fetchData}>Aggiorna <FontAwesomeIcon icon={faUndo} /></span>
                    </div>
                    <div class="col-12 col-sm-2 text-right nascondi-nella-stampa">
                        <span className="btn btn-primary" onClick={stampaConImmagini}>Stampa <FontAwesomeIcon icon={faPrint} /></span>
                    </div>
                </div>

            </div><div class="container pt-5 nascondi-nella-stampa">
                <div class="row">
                    <div class="col-12">
                        <summary class="link-list-wrapper">
                            <ul class="link-list">
                                <li>
                                    <h2 id="heading-senza-link">Menu rapido degli indicatori</h2>
                                </li>
                                {formSchema.indicatori.map((elemento, index) => {
                                    return <li><HashLink class="list-item" to={"#ancora" + elemento.identificativo}><span> {elemento.nome}</span></HashLink></li>;
                                })}
                            </ul>
                        </summary>
                    </div>
                </div>
            </div><div class="container">
                <div class="row">
                    <div class="col-12">
                        <h2>Introduzione</h2>
                    </div>
                </div>
            </div><div class="container ">
                <div class="row">
                    <div class="col-12">
                        <p className="text-justify" dangerouslySetInnerHTML={creaCodiceHtml(formSchema.introduzione)} />
                    </div>
                </div>
            </div><div class="container pb-5">
                <div class="d-flex flex-column">

                    {formSchema.immaginiInizio.map((data, index) => {
                        return <><div title={data.commento} class="text-center d-block"><figure class="figure"><div class=" text-center pt-5">

                            <img src={`data:image/png;base64,${data.base}`} alt={data.commento} class="img-fluid" /></div><div class="text-center">
                                <span><strong>Figura {index + 1}</strong>: <figcaption class="figure-caption" dangerouslySetInnerHTML={creaCodiceHtml(data.tooltip)}></figcaption ></span>
                            </div></figure></div></>;

                    })}

                </div>
            </div><div class="container pb-5">
                <div class="row">
                    <div class="col-12">
                        <h2>Indicatori</h2>
                    </div>
                </div>
            </div><div class="container">
                <div class="d-flex flex-column">

                    {formSchema.indicatori.map((data, index) => {

                        return <section><SchedaIndicatore data={data} /></section>;
                    })}

                </div>

            </div>
            {formSchema.considerazioni !== null && !(formSchema.considerazioni.length < 10 && formSchema.considerazioni.includes("null")) &&

                <div class="container ">
                    <div class="row pb-5">
                        <div class="col-12">
                            <h2>Considerazioni generali e azioni intraprese</h2>
                        </div>
                    </div>
                </div>
            }
            {formSchema.considerazioni !== null && !(formSchema.considerazioni.length < 10 && formSchema.considerazioni.includes("null")) &&
                <div class="container pb-5 ">
                    <div class="row">
                        <div class="col-12">
                            <p className="text-justify" dangerouslySetInnerHTML={creaCodiceHtml(formSchema.considerazioni)}></p>
                        </div>
                    </div>
                </div>
            }
            <div class="container pb-5">
                <div class="d-flex flex-column">

                    {formSchema.immaginiFine.map((data, index) => {
                        return <><div title={data.commento} class="text-center d-block"><figure class="figure"><div class=" text-center pt-5">

                            <img src={`data:image/png;base64,${data.base}`} alt={data.commento} class="img-fluid" /></div><div class="text-center">
                                <span><strong>Figura {index + 1}</strong>: <figcaption class="figure-caption" dangerouslySetInnerHTML={creaCodiceHtml(data.tooltip)}></figcaption ></span>
                            </div></figure></div></>;

                    })}

                </div>
            </div>
        </>}
    </>)}</></React.Fragment>

}