import React from "react"
import Layout from "../components/Layout"

export default function MediaPolicy() {
    return <div className="container">
    <div className="row">
        <div className="col-12">
            <h3>Media policy</h3>
        </div>
    </div>

</div> 
}
