import axios from "axios";

import Configurazione from "./config/configurazione";

/**
 * File js che contiene il punto di partenza della connessione.
 * Bisogna specificare il baseURL del progetto
 * Bisogna specificare eventuali headers
 */

let configurazione = Configurazione();

export default axios.create({
  baseURL: configurazione.urlBackend,
  headers: {
    "Content-type": "application/json"
  },
  headers: {
    'Authorization': "Bearer " + configurazione.token
  }
});

