import React from "react"
import Layout from "../components/Layout"

export default function NotFound() {
    return <div className="container">
            <div className="row">
                <div className="col-12">
                    <h3>Errore Generico - Riprovare più tardi</h3>
                </div>
            </div>

        </div>
     
}
