import React from "react";
import { Link } from "react-router-dom";

import Configurazione from "../config/configurazione";
import getCodtrel from "./utils";


let configurazione = Configurazione();

function creaCodiceHtml(codice) {
    return { __html: codice };
}

function cambiaScrittaPulsante(id) {
    let valoreEtichettaPulsante = document.getElementById("pulsante" + id).textContent;
    if (valoreEtichettaPulsante == "Mostra") {
        document.getElementById("pulsante" + id).textContent = "Nascondi";
    } else {
        document.getElementById("pulsante" + id).textContent = "Mostra";
    }
}


function impostaVisibilita(url) {
    if (url == null) {
        return {
            display: 'none',
        };
    } else {
        return {
            display: 'table-cell',
        };
    }
}

function impostaVisibilitaPulsanti(array) {


    if (array.length == 0) {
        return {
            display: 'none',
        };
    }
}

export default function SchedaIndicatore(indicatore) {
    return <div class="container pb-5">
        <div class="row">
            <div class="col-12">
                <a id={"ancora" + indicatore.data.identificativo}></a>
                <h3 className="font-weight-bold" dangerouslySetInnerHTML={creaCodiceHtml(indicatore.data.nome)}></h3>
                <p className="text-justify"><strong>Finalità: </strong><span dangerouslySetInnerHTML={creaCodiceHtml(indicatore.data.obiettivo)}></span></p>
                <p className="text-justify" dangerouslySetInnerHTML={creaCodiceHtml(indicatore.data.note)}></p>
            </div>
        </div>

        <div class="table-responsive-xl ">
            <table class="table table-sm table-bordered ">
                <thead class=" neutral-1-bg-a3">
                    <tr>
                        <th scope="col" className="larghezza-colonna">Entità considerata</th>
                        <th scope="col" className="larghezza-colonna">DPSIR</th>
                        <th scope="col" className="larghezza-colonna">Copertura temporale</th>
                        <th scope="col" className="larghezza-colonna">Copertura territoriale</th>
                        <th scope="col" className="larghezza-colonna text-center">Trend</th>
                        <th scope="col" className="larghezza-colonna text-center">Situazione</th>
                        <th style={impostaVisibilitaPulsanti(indicatore.data.immagini)} scope="col" className="larghezza-colonna nascondi-nella-stampa">Rappresentazioni dell'indicatore</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th className="larghezza-colonna align-middle"><p dangerouslySetInnerHTML={creaCodiceHtml(indicatore.data.indicatore)} className="font-normale"></p></th>
                        <td className="larghezza-colonna align-middle"><p dangerouslySetInnerHTML={creaCodiceHtml(indicatore.data.dpsir)} className=""></p></td>
                        <td className="larghezza-colonna align-middle"><p dangerouslySetInnerHTML={creaCodiceHtml(indicatore.data.coperturaTemporale)} className=""></p></td>
                        <td className="larghezza-colonna align-middle"><p dangerouslySetInnerHTML={creaCodiceHtml(indicatore.data.coperturaTerritoriale)} className=""></p></td>
                        <td className="larghezza-colonna text-center align-middle"><img src={configurazione.urlApplicativo + "/static/" + indicatore.data.indicatoreImmagine} alt={indicatore.data.indicatoreDescrizione} title={indicatore.data.indicatoreDescrizione + " - " + indicatore.data.nome} /></td>
                        <td className="larghezza-colonna text-center align-middle"><img src={configurazione.urlApplicativo + "/static/" + indicatore.data.situazioneImmagine} alt={indicatore.data.situazioneDescrizione} title={indicatore.data.situazioneDescrizione + " - " + indicatore.data.nome} /></td>
                        <td style={impostaVisibilitaPulsanti(indicatore.data.immagini)} className="larghezza-colonna align-middle nascondi-nella-stampa"><a onClick={() => cambiaScrittaPulsante(indicatore.data.identificativo)} class="btn btn-primary btn-lg btn-block" data-toggle="collapse" href={"#" + indicatore.data.identificativo} role="button" aria-expanded="false" aria-controls={indicatore.data.identificativo}><span class="" id={"pulsante" + indicatore.data.identificativo}>Mostra</span></a></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row pb-1">
            <div class="col-12 text-right nascondi-nella-stampa">
                <span style={impostaVisibilita(indicatore.data.variabili)}><Link class="text-decoration-none font-weight-bold" to={"/" + getCodtrel() + indicatore.data.variabili} ><span>Accedi ai dati dell'indicatore</span></Link></span>
            </div>
        </div>

        <div class="d-flex flex-column">

            {indicatore.data.immagini.map((data, index) => {
                return <figure class="figure"><div class="collapse multi-collapse apri-tendina-stampa" id={indicatore.data.identificativo}>
                    <div class="card card-body">
                        <img src={`data:image/png;base64,${data.base}`} title={data.commento} alt={data.commento} class="img-fluid" />
                        <span className="text-justify" ><strong>Figura {index + 1}</strong>: <figcaption class="figure-caption" dangerouslySetInnerHTML={creaCodiceHtml(data.tooltip)}></figcaption></span>
                    </div>
                </div></figure>
            })}



        </div>


    </div>
}
