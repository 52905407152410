import React from "react"
import Layout from "../components/Layout"

export default function NoteLegali() {
    return <div className="container">
        <div className="row">
            <div className="col-12">
                <h3>Note legali</h3>
                <p>La Regione Liguria vuole offrire, con questo sito, un servizio di informazione sempre accessibile a chiunque desideri conoscere meglio questo territorio e la sua amministrazione locale.</p>

                <h5><strong>Copyright</strong></h5>
                <p>Tutti i testi, la grafica e il software presenti all'interno di questo sito sono protetti ai sensi delle normative sul diritto d'autore, sui brevetti e su quelle relative alla proprietà intellettuale.
                    Ogni prodotto o società menzionati in questo sito sono marchi dei rispettivi proprietari o titolari e possono essere protetti da brevetti e/o copyright concessi o registrati dalle autorità preposte. Possono quindi essere scaricati o utilizzati solo per uso personale e non commerciale: pertanto nulla, neppure in parte, potrà essere riprodotto, modificato o rivenduto per fini di lucro.
                    I contenuti del sito non hanno valore legale, occorre fare riferimento ai testi adottati ufficialmente
                    e pubblicati nell'edizione su carta del Bollettino Ufficiale della Regione Liguria. </p>

                <h5><strong>Utilizzo del sito</strong></h5>
                <p>In nessun caso la Regione Liguria potrà essere ritenuta responsabile dei danni di qualsiasi natura causati direttamente o indirettamente dall'accesso al sito, dall'utilizzo degli strumenti interattivi, dall'incapacità o impossibilità di accedervi, dall'utilizzo delle notizie in esso contenute. Sono consentite citazioni a titolo di cronaca o recensione se accompagnate dall'indicazione della fonte "Regione Liguria" e dalla sua Url.
                    La Regione Liguria si riserva il diritto di riprodurre i testi in altre pubblicazioni.
                    La Regione Liguria si riserva il diritto di modificare i contenuti del sito e delle note legali in qualsiasi momento e senza alcun preavviso.</p>

                <h5><strong>Segnalazione errori</strong></h5>
                <p>L'obiettivo principale di questo servizio è fornire un'informazione tempestiva e precisa: si invitano gli utenti a segnalare eventuali errori o malfunzionamenti, sia sulle pagine informative che nei servizi interattivi a <a href="mailto:liguriainforma@regione.liguria.it">liguriainforma@regione.liguria.it</a>
                    La Regione Liguria provvederà a correggerli il prima possibile.</p>

                <h5><strong>Accesso a siti esterni collegati
                </strong></h5>
                <p>La Regione Liguria non ha alcuna responsabilità per quanto riguarda i siti ai quali è possibile accedere tramite i collegamenti posti all'interno del sito stesso.
                    La Regione Liguria non è responsabile delle informazioni ottenute dall'utente tramite l'accesso ai siti raggiungibili tramite collegamento ipertestuale. Pertanto della completezza e precisione delle informazioni sono da ritenersi responsabili i soggetti titolari dei siti ai quali si accede alle condizioni dagli stessi previste.</p>

                <h5><strong>Privacy Policy</strong>
                </h5>
                <p>La Regione Liguria garantisce che il trattamento dei dati personali che dovessero pervenire via posta elettronica o moduli elettronici, anche in sede di registrazione, è conforme a quanto previsto dalla vigente normativa sulla privacy (d.lgs. 30 giugno 2003, n.196, in vigore dal 1 gennaio 2004).
                    I dati personali dell'utente (richiesti per l'accesso ai servizi) non saranno ceduti a terzi, il loro utilizzo servirà per comunicazioni relative al sito e ai suoi contenuti e per l'invio di un eventuale notiziario periodico di informazione di argomento attinente.
                    Se gli scopi per cui sono stati raccolti i dati personali sono quelli per lo svolgimento di attività istituzionali, la pubblica amministrazione non è tenuta a chiedere il consenso (art.24 Cod.) in quanto destinati a finalità istituzionali, che è obbligatorio (in modo semplificato) per il trattamento dei dati sanitari (art.81 Cod.).
                    I diritti dell'interessato sono quelli espressamente indicati all'articolo 7 del "Codice privacy" e possono essere esercitati con richiesta rivolta senza formalità, anche mediante lettera raccomandata, telefax o posta elettronica (art.9 Cod.) al titolare o al responsabile. Titolare del trattamento è la Regione Liguria, con sede in via Fieschi 15 - 16100 Genova, responsabili del trattamento sono i dirigenti delle strutture competenti per materia (ai quali l'interessato si può rivolgere per richiedere informazioni relative esclusivamente ai dati personali) e incaricati sono i funzionari e gli impiegati autorizzati a compiere le operazioni di trattamento dal titolare o dal responsabile. </p>

                <h5><strong>Download</strong></h5>
                <p>Ogni oggetto presente su questo sito per lo scaricamento (download) come ad esempio documentazione tecnica, normativa, modulistica e software ecc., salvo diversa indicazione, è liberamente e gratuitamente disponibile alle condizioni stabilite dal titolare.

                </p>


            </div>
        </div>

    </div>
}
