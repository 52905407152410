import React from 'react';
import './App.css';
import 'jquery/src/jquery';
import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import 'bootstrap-italia/dist/js/bootstrap-italia.min.js';
import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';


import Layout from './components/Layout';
import Home from './pagine/index';
import Relazione from './pagine/relazione';
import Variabili from './pagine/variabili';

import CookiePolicy from './pagine/cookiePolicy';
import MediaPolicy from './pagine/mediaPolicy';
import NoteLegali from './pagine/noteLegali';
import Accessibilita from './pagine/accessibilita';
import Crediti from './pagine/crediti';
import NotFound from './pagine/notFound'

import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import VariabiliDettagliate from './pagine/variabiliDettagliate';
import QuadroComplessivoDegliIndicatori from './pagine/quadroComplessivoDegliIndicatori';

import Configurazione from './config/configurazione';



const App = (props) => {

    let configurazione = Configurazione();


    return (<div>
        <meta charSet="utf-8"></meta>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"></meta>
        <BrowserRouter basename={""}>
            <Layout props={props}>
                <main>

                    <Switch>
                        <Route exact path='/:codtrel' component={Home} />
                        <Route exact path={'/:codtrel/:pathRadice/:anno'} component={QuadroComplessivoDegliIndicatori} />
                        <Route exact path={'/:codtrel/:pathRadice/:anno/:sezione'} component={Relazione} />
                        <Route exact path={'/:codtrel/:pathRadice/:anno/:sezione/:riga'} component={Variabili} />
                        <Route exact path={'/:codtrel/:pathRadice/:anno/:sezione/:riga/:codVariabile/:annoCorrelato'} component={VariabiliDettagliate} />

                        <Route exact path='/:codtrel/cookiePolicy' component={CookiePolicy} />
                        <Route exact path='/:codtrel/mediaPolicy' component={MediaPolicy} />
                        <Route exact path='/:codtrel/noteLegali' component={NoteLegali} />
                        <Route exact path='/:codtrel/accessibilita' component={Accessibilita} />
                        <Route exact path='/:codtrel/crediti' component={Crediti} />
                        <Route exact path='/:codtrel/:anno' component={Home} />
                        <Route path='/errore/404' component={NotFound} />
                        <Redirect from='*' to='/errore/404' />

                    </Switch>


                </main>
            </Layout>
        </BrowserRouter>

    </div>
    );
};

/*
<>
        <meta charSet="utf-8"></meta>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"></meta>
        <title>Relazione sullo stato dell'ambiente - Regione Liguria</title>
        <Header />
        {children}
        <Footer />
        
    </>*/

export default App;