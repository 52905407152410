import React from "react"
import Layout from "../components/Layout"

export default function Accessibilita() {
    return <div className="container">
        <div className="row ">
            <div className="col-12">
                <h3>Accessibilità</h3>
                <p>Il sito è progettato e realizzato con l’obiettivo di rispettare le Linee guida sull’accessibilità che forniscono disposizioni per favorire e semplificare l'accesso agli strumenti informatici da parte degli utenti e, in particolare, delle persone con disabilità.</p>

                <h5>Vai a</h5>
                <div class="link-list-wrapper">
                    <ul class="link-list">
                        <li><a class="list-item disabled" target="_blank" rel="noreferrer"><span>Dichiarazione di accessibilità - NON ANCORA PRESENTE</span></a></li>
                        <li><a class="list-item" href="https://www.regione.liguria.it/accessibilit%C3%A0/meccanismo-di-feedback.html" target="_blank" rel="noreferrer"><span>Meccanismo di feedback</span></a></li>
                    </ul>
                </div>
            </div>
        </div>

    </div>
}
