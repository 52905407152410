import React, { useEffect, useState } from "react"
import exampleService from "../services/example-service";


import getCodtrel from "./utils";

import { Link } from "react-router-dom";



export default function Breadcrumbs() {

  var urlPartenza = window.location.pathname;

  useEffect(() => {
    const interval = setInterval(() => {
      if (urlPartenza != window.location.pathname) {
        fetchData();
        urlPartenza = window.location.pathname;
      }
    }, 500);
    return () => clearInterval(interval);
  }, []);


  useEffect(() => {
    fetchData();
  }, [window.location.pathname]);

  const [formSchema, setFormSchema] = useState([]);
  const [loading, setLoading] = useState(true);




  const fetchData = async () => {
    var url = window.location.pathname.substring(getCodtrel().length + 2, window.location.pathname.length);


    await exampleService.getBreadcrumb(getCodtrel(), url)
      .then((response) => {
        setFormSchema(response.data);
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setFormSchema();
        //setLoading(false);
      });
  };


  return <React.Fragment><>{loading ? (
    <span></span>
  ) : (

    <div onchange class="container nascondi-nella-stampa border-top-custom-ld" ><div aria-label="breadcrumb">
      <ol class="breadcrumb pb-2">
        {formSchema.breadcrumbs != undefined && formSchema.breadcrumbs.map((elenco, index) => {
          return (<Pezzetto pezzo={elenco} />)

        })}</ol>
    </div></div>)}</></React.Fragment>
}



function Pezzetto(pezzo) {
  if (pezzo.pezzo.cliccabile && pezzo.pezzo.link.includes("https")) {
    return <li class="breadcrumb-item"><a href={pezzo.pezzo.link}>{pezzo.pezzo.label}</a></li>
  } else if (pezzo.pezzo.cliccabile) {
    return <li class="breadcrumb-item"><Link to={"/" + getCodtrel() + (pezzo.pezzo.link != "/" ? pezzo.pezzo.link : "")}>{pezzo.pezzo.label}</Link></li>
  } else {
    return <li class="breadcrumb-item active" aria-current="page">{pezzo.pezzo.label}</li>
  }

};