import React from "react"

function annullaPreferenze(){
    localStorage.removeItem('cookie');
    document.getElementById("annullaConsensoCookie").style.display = "none";
    document.getElementById("cookieBanner").style.display = "block";
}


export default function PrivacyPolicy() {
    return <> <div class="container">
    <div class="row pt-5">
        <div class="col-12 col-sm-10">
            <h1>Cookie Policy</h1>
        </div>
        <div class="col-12 col-sm-2 nascondi-nella-stampa">

        { localStorage.getItem('cookie')==null ? <span></span>: <span id="annullaConsensoCookie" className="btn btn-primary" onClick={annullaPreferenze}>Annulla consenso Cookie</span> }
            
        </div>
    </div>

</div></>
}
