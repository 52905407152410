import React from "react"
import { Link } from "react-router-dom";
import getCodtrel from "./utils";

function compare(a, b) {
    if (a.annoCorrelato > b.annoCorrelato) {
        return -1;
    }
    if (a.annoCorrelato < b.annoCorrelato) {
        return 1;
    }
    return 0;
}

/*
      PER CREARE LA PARTE SOTTO DELLE TAB
*/
export default function SingolaVariabileTab(JSONAmbito) {

    JSONAmbito.data.sort(compare);

    return (
        <><div class="row pt-3">
            <div class="col-12">
                <h2>Dati suddivisi per anno</h2>
                <div id="accordionDiv1" class="collapse-div" role="tablist">
                    <div class="link-list-wrapper">
                        <ul class="link-list">
                            {JSONAmbito.data.map((ambito, index) => {
                                return (<li><Link to={"/" + getCodtrel() + ambito.url}>{ambito.annoCorrelato} - {ambito.nome}</Link></li>
                                )
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        </div></>
    )


}
