import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import SingolaTAB from "../components/SingolaTab";
import exampleService from "../services/example-service";
import Spinner from "../components/Spinner";

import getCodtrel from "../components/utils";

var refreshIntervalId;


var primaVolta = true;
var primaVoltaTab = true;

var annoSelezionato = null;

setTimeout(() => {




}, 1000);

/*
    PER CRERARE LA PARTE SOPRA DELLA TAB
*/
const IntestazioneTAB = (nodes) => (
  <ul class="nav nav-tabs" id="myTab" role="tablist">
    {nodes.nodes.map((data, index) => {
      if (primaVolta) {
        primaVolta = false;
        return <li class="nav-item"><a class="nav-link active" id={'tab' + data.anno + '-tab'} data-toggle="tab" href={'#tab' + data.anno} role="tab" aria-controls={'tab' + data.anno} aria-selected="true">{data.anno}</a></li>
      } else {
        return <li class="nav-item"><a class="nav-link" id={'tab' + data.anno + '-tab'} data-toggle="tab" href={'#tab' + data.anno} role="tab" aria-controls={'tab' + data.anno} aria-selected="false">{data.anno}</a></li>
      }

    })}
  </ul>
)

/*
      PER CREARE LA PARTE SOTTO DELLE TAB
*/
const ListaTAB = (nodes) => (
  <div class="tab-content" id="myTabContent">

    {nodes.nodes.map((JSONAmbito, index) => {
      if (primaVoltaTab) {
        primaVoltaTab = false;
        return <div class="tab-pane p-4 fade show active" id={'tab' + JSONAmbito.anno} role="tabpanel" aria-labelledby={'tab' + JSONAmbito.anno + '-tab'} ><SingolaTAB data={JSONAmbito}></SingolaTAB></div>
      } else {
        return <div class="tab-pane p-4 fade" id={'tab' + JSONAmbito.anno} role="tabpanel" aria-labelledby={'tab' + JSONAmbito.anno + '-tab'} ><SingolaTAB data={JSONAmbito}></SingolaTAB></div>
      }
    })}


  </div>

)

export default function Home(props) {

  annoSelezionato = props.match.params.anno;

  useEffect(() => {
    fetchData();
    document.title = "Relazione sullo Stato dell'Ambiente - Regione Liguria";
    annoSelezionato = props.match.params.anno;
    refreshIntervalId = setInterval(function () {

      if (annoSelezionato == null) {
        if (document.getElementById("myTab") != null && document.getElementById("myTab").children[0] != null && document.getElementById("myTab").children[0].children[0] != null) {
          document.getElementById("myTab").children[0].children[0].click();
          clearInterval(refreshIntervalId);
        }
      } else if (document.getElementById("tab" + annoSelezionato + "-tab") != null) {
        document.getElementById("tab" + annoSelezionato + "-tab").click();
        clearInterval(refreshIntervalId);

      }
    }, 500);

  }, []);

  const hoCaricato = () => {
    setLoading(false);
  };

  const [errore, setErrore] = useState("");


  const [formSchema, setFormSchema] = useState([]);
  const [loading, setLoading] = useState(true);



  const fetchData = async () => {
    //if (sessionStorage.getItem('homeRsa') == null) {

    await exampleService.alberatura(getCodtrel())
      .then((response) => {
        setFormSchema(response.data);
        sessionStorage.setItem('homeRsa', JSON.stringify(response.data));
        hoCaricato();
        //document.getElementById("myTab").children[0].children[0].click();
      })
      .catch((e) => {
        setErrore("Errore durante il recupero delle informazioni richieste");
        console.log(e);
        setFormSchema([]);
        setLoading(false);
        console.error("ERRORE! ", e)
        //window.location.replace("/errore/404");
      });

    /*  } else {
        setFormSchema(JSON.parse(sessionStorage.getItem('homeRsa')));
        hoCaricato();
        //document.getElementById("myTab").children[0].children[0].click();
      }*/



  };



  return <React.Fragment>
    <><div class="container section section-image sfondo-home">
      <div class="section-content ">
        <div class="container white-color pt-5 pb-5">
          <div class="row ">
            <div class="col-12 pt-5 pb-5">
              <span ></span>
            </div>
          </div>
          <div class="row">
          </div>
        </div>
      </div>
    </div>
      {errore !== "" && <div class="container pt-5">
        <div class="alert alert-danger" role="alert">
          {errore}
        </div>
      </div>}
      {loading ? (
        <Spinner />
      ) : (<div class="container pt-5">


        <IntestazioneTAB nodes={formSchema} />
        <ListaTAB nodes={formSchema} />
      </div>)}</>

  </React.Fragment>
    ;


}


