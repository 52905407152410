import React from "react"
import Layout from "../components/Layout"

export default function Crediti() {
    return  <div className="container">
    <div className="row">
        <div className="col-12">
            <h3>Crediti</h3>
            <p>Sito web realizzato da <a href="https://www.liguriadigitale.it/" target="_blank" rel="noreferrer">Liguria Digitale Spa</a></p>
            <p>Le immagini utilizzate provengono dal portale <a href="https://www.pexels.com/it-it/" target="_blank" rel="noreferrer">Pexels</a></p>
        </div>
    </div>

</div> 
}
