import React, { useEffect, useState } from "react"
import exampleService from "../services/example-service";
import Spinner from "../components/Spinner";
import SingolaVariabileTab from "../components/SingolaVariabileTab";
import getCodtrel from "../components/utils";

/*
     PER CREARE LA PARTE SOTTO DELLE TAB
*/
const ListaAccordiationTAB = (nodes) => (



  <div id="collapseDiv1" class="collapse-div" role="tablist">

    {nodes.nodes.map((data, index) => {

      return <>
        <div class="collapse-header" id={"head" + data.identificativo}>
          <button data-toggle="collapse" data-target={"#" + data.identificativo} aria-expanded="false" aria-controls={data.identificativo}>
            {data.nome}
          </button>
        </div>
        <div id={data.identificativo} class="collapse" role="tabpanel" aria-labelledby={"head" + data.identificativo}>
          <div class="collapse-body">
            <SingolaVariabileTab data={data.listaAnni}></SingolaVariabileTab>
          </div>
        </div>




      </>
    })}


  </div>
)


export default function VariabiliDettagliate(props) {

  const [errore, setErrore] = useState("");



  const [formSchema, setFormSchema] = useState([]);
  const [loading, setLoading] = useState(true);
  const [datiCompleti, setDatiCompleti] = useState([]);
  const [datiScaricati, setDatiScaricati] = useState(false);

  const JSONToCSVConvertorDue = () => {

    var json = datiCompleti;




    var fields = Object.keys(json[0])
    var replacer = function (key, value) { return value === null ? '' : value }
    var csv = json.slice(1).map(function (row) {
      return fields.map(function (fieldName) {
        return JSON.stringify(row[fieldName], replacer)
      }).join(',')
    })


    csv.unshift(json[0].join(',')) // add header column
    csv = csv.join('\r\n');
    var uri = "data:text/csv;charset=utf-8," + escape(csv);

    var link = document.createElement("a");
    link.href = uri;

    link.style = "visibility:hidden";

    var fileName = formSchema[0].anno + "_" + formSchema[0].sezione + "_" + formSchema[0].riga + "_exportDatiTotale";

    link.download = fileName + ".csv";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }


  useEffect(() => {
    fetchData();
    recuperaDatiDaEsportate();

  }, []);





  const fetchData = async () => {
    await exampleService.getVariabili(getCodtrel(), props.match.params.anno, props.match.params.sezione, props.match.params.riga)
      .then((response) => {
        setFormSchema(response.data);
        setLoading(false);
        if (formSchema.nome != undefined)
          document.title = formSchema.nome + " - Relazione sullo Stato dell'Ambiente - Regione Liguria";
        else
          document.title = "Relazione sullo Stato dell'Ambiente - Regione Liguria";
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
        setFormSchema();
        console.error("ERRORE! ", e)
        setErrore("Errore durante il recupero delle informazioni richieste");

        //window.location.replace("/errore/404");
      });
  };

  const recuperaDatiDaEsportate = async () => {
    await exampleService.getExportVariabili(getCodtrel(), props.match.params.anno, props.match.params.sezione, props.match.params.riga)
      .then((response) => {
        setDatiCompleti(response.data);
        setDatiScaricati(true);
      })
      .catch((e) => {
        setErrore("Errore durante il recupero delle informazioni richieste");

        console.log(e);
        setDatiCompleti();
        setLoading(false);
      });
  };



  return <React.Fragment><>{loading ? (
    <Spinner />
  ) : (<>
    {errore !== "" ? <>

      <div class="container">
        <div class="alert alert-danger" role="alert">
          {errore}
        </div>
      </div>
    </>

      :

      <>

        <div class="section section-image variabili">
          <div class="section-content">
            <div class="container white-color ">
              <div class="row">
                <div class="col-12 pt-5 pb-5">

                </div>
              </div>
              <div class="row">
                <div class="col">

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container pt-5">
          <div class="row">
            <div class="col">
              <h1>Elenco variabili</h1>
              <React.Fragment>{datiScaricati ? (
                <a id="urlExport" class="text-primary-blu"><u><strong onClick={JSONToCSVConvertorDue}>Scarica tutti i dati in formato CSV</strong></u></a>
              ) : (
                <u><strong>Caricamento...</strong></u>
              )}</React.Fragment>
            </div>

          </div>

        </div>
        <div class="container pt-5">
          <div class="row">
            <div class="col">
              <ListaAccordiationTAB nodes={formSchema} />
            </div>
          </div>

        </div>
      </>}</>
  )}</></React.Fragment>

}