import React from "react";
import { Link } from "react-router-dom";
import Configurazione from "../config/configurazione";
import getCodtrel from "./utils";


function creaCodiceHtml(codice) {
    return { __html: codice };
}



let configurazione = Configurazione();


export default function SchedaIndicatore(indicatore) {


    return <tr>
        <td className="larghezza-colonna align-middle"><p dangerouslySetInnerHTML={creaCodiceHtml(indicatore.data.tema)} className="font-normale"></p></td>
        <td className="larghezza-colonna align-middle"><Link class="" to={"/" + getCodtrel() + indicatore.data.url}><p dangerouslySetInnerHTML={creaCodiceHtml(indicatore.data.indicatore)} className="font-normale"></p></Link></td>
        <td className="larghezza-colonna align-middle"><p dangerouslySetInnerHTML={creaCodiceHtml(indicatore.data.dpsir)} className=""></p></td>
        <td className="larghezza-colonna align-middle"><p dangerouslySetInnerHTML={creaCodiceHtml(indicatore.data.coperturaTemporale)} className=""></p></td>
        <td className="larghezza-colonna align-middle"><p dangerouslySetInnerHTML={creaCodiceHtml(indicatore.data.coperturaTerritoriale)} className=""></p></td>
        <td className="larghezza-colonna text-center align-middle"><img src={configurazione.urlApplicativo + "/static/" + indicatore.data.trendImg} alt={indicatore.data.trend} title={indicatore.data.trend + " - " + indicatore.data.nome} /></td>
        <td className="larghezza-colonna text-center align-middle"><img src={configurazione.urlApplicativo + "/static/" + indicatore.data.situazioneImg} alt={indicatore.data.situazione} title={indicatore.data.situazione + " - " + indicatore.data.nome} /></td>
    </tr>

}
