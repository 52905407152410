import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logoRegioneLiguria from '../img/logo_RL_testobianco.png'
import logo_arpal_bianco from '../img/logo_arpal_bianco.png'
import { CookieBar, CookieBarButtons, CookieBarButton } from "design-react-kit";

import exampleService from "../services/example-service";

import getCodtrel from "./utils";


function accettaCookie() {
  localStorage.setItem('cookie', 'true');
  document.getElementById("cookieBanner").style.display = "none";
}




export default function Footer() {

  const [logo, setLogo] = useState("");

  const fetchData = async () => {
    await exampleService.metadati(getCodtrel())
      .then((response) => {
        console.info(response.data);

        setLogo(response.data.nomeblobString);


      })
      .catch((e) => {
        console.error(e);
        console.error("ERRORE! ", e)
        //window.location.replace("/errore/404");
      });
  };

  useEffect(() => {
    fetchData();

  }, []);


  return <><footer class="it-footer nascondi-nella-stampa" >
    <div class="it-footer-main" >
      <div class="container">

        <section class="align-middle">
          <div class="row pt-3 pb-3 align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6 pb-2 text-center">
              <img
                src={logoRegioneLiguria}
                class="rounded mx-auto logo-regione pr-3" alt="Logo con scritta di Regione Liguria" />
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 pb-2">
              <span class="scritta-regione-liguria font-weight-bold">
                Regione Liguria               </span><br />
              <small>Piazza De Ferrari 1, 16121 Genova<br />Centralino +39 01054851 - Urp LiguriaInforma numero verde 800 445 445 - Fax +39 010 5488742</small>

            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 pb-2 text-center">
              <img
                src={`data:image/png;base64,${logo}`}
                class="rounded mx-auto logo-footer-arpal pr-3" alt="Logo bianco di Arpal" />
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6 pb-2">

              <div class="link-list-wrapper">
                <ul class="footer-list link-list clearfix">
                  <li class="list-item">
                    <a href="https://www.regione.liguria.it/cookie-policy.html" target="_blank" title="Privacy-Cookies">Cookie policy</a>
                  </li>

                  <li class="list-item">
                    <Link to={"/" + getCodtrel() + "/accessibilita"} title="Pagina di accessibilità">Accessibilità</Link>
                  </li>
                  <li class="list-item">
                    <a href="https://www.liguriadigitale.it/" title="Sito internet di Liguria Digitale">Realizzato da Liguria Digitale</a>
                  </li>





                </ul>
              </div>
            </div>


          </div>
        </section>

      </div>
    </div>


  </footer>





  </>
}
