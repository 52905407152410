import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom";
import logoRegioneLiguria from '../img/logoRlNew.png'
import logoArpal from '../img/logo_new_arpal.png'

import youtube from '../img/youtube.png'

import Breadcrumbs from "./Breadcrumbs";
import Configurazione from "../config/configurazione";

import exampleService from "../services/example-service";

import getCodtrel from "./utils";


export default function Header() {

  const [nomeRelazione, setNomeRelazione] = useState("");
  const [nomePartner, setNomePartner] = useState("");
  const [linkPartner, setLinkPartner] = useState("");
  const [logo, setLogo] = useState("");

  const [linkAltro, setLinkAltro] = useState("");
  const [nomeAltro, setNomeAltro] = useState("");
  const [logoAltro, setLogoAltro] = useState("");


  const fetchData = async () => {
    await exampleService.metadati(getCodtrel())
      .then((response) => {
        console.info(response.data);

        setNomeRelazione(response.data.nometrel);
        setNomePartner(response.data.nomepartner);
        setLinkPartner(response.data.linkpartner);
        setLogo(response.data.nomeblobString);

        setLinkAltro(response.data.linkAltro);
        setNomeAltro(response.data.nomeAltro);
        setLogoAltro(response.data.nomeblobAltroString);


      })
      .catch((e) => {
        console.error(e);
        console.error("ERRORE! ", e)
        //window.location.replace("/errore/404");
      });
  };

  useEffect(() => {
    fetchData();

  }, []);

  let configurazione = Configurazione();

  return <>
    <div class="it-header-slim-wrapper nascondi-nella-stampa">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="it-header-slim-wrapper-content">
              <span class="d-lg-block navbar-brand"><a class="pr-1 text-decoration-none  d-sm-inline" href="https://www.regione.liguria.it/" target="_blank"><strong>Regione Liguria</strong></a>  |  <a class="pl-1 text-decoration-none  d-sm-inline" href={linkPartner} target="_blank"><strong>{nomePartner}</strong></a></span>

              <div class="it-header-slim-right-zone">
                <div class="it-socials d-md-flex">
                  {linkAltro != null &&
                    < a class="text-decoration-none" href={linkAltro} aria-label="Seguici su YouTube" target="_blank" rel="noreferrer">
                      <div class="d-inline">
                        <span class="text-decoration-none pr-2 d-none d-sm-inline">{nomeAltro}</span><img
                          src={`data:image/png;base64,${logoAltro}`}
                          class="rounded mx-auto logo-social pr-3" />
                      </div>

                    </a>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
    <div class=" bg-white nascondi-nella-stampa" >
      <div class="container ">
        <div class="row align-items-center ">
          <div class="col-12 col-lg-2 ">
            <Link to={"/" + getCodtrel()}> <img
              src={logoRegioneLiguria}
              class="rounded mx-auto d-block logo-regione pr-3 pt-3 pb-3" alt="Logo di Regione Liguria" /></Link>
          </div>
          <div class="col-lg-8 col-12 text-center">
            <div class=" text-primary">
              <h2 class="">{nomeRelazione}</h2>
            </div>

          </div>
          <div class="col-12 col-lg-2">
            <img
              src={`data:image/png;base64,${logo}`}
              class="rounded mx-auto d-block logo-arpal-header pt-3 pb-3 " alt={"Logo di " + nomePartner} />
          </div>


        </div>
      </div>
    </div>
    <Breadcrumbs ></Breadcrumbs></>
}
