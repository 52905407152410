import React, { useEffect, useState } from "react"
import RigaSchedaIndicatore from "../components/RigaSchedaIndicatore";
import exampleService from "../services/example-service";
import Spinner from "../components/Spinner";
import getCodtrel from "../components/utils";

function creaCodiceHtml(codice) {
    return { __html: codice };
}

export default function QuadroComplessivoDegliIndicatori(props) {

    useEffect(() => {
        fetchData();

    }, []);

    const [formSchema, setFormSchema] = useState([]);
    const [loading, setLoading] = useState(true);

    const [errore, setErrore] = useState("");


    const fetchData = async () => {
        await exampleService.getQuadroComplessivoDegliIndicatori(getCodtrel(), props.match.params.anno)
            .then((response) => {
                setFormSchema(response.data);
                setLoading(false);
                if (formSchema.tematicaSpecifica != undefined)
                    document.title = formSchema.tematicaSpecifica + " - Relazione sullo Stato dell'Ambiente - Regione Liguria";
                else
                    document.title = "Relazione sullo Stato dell'Ambiente - Regione Liguria";
            })
            .catch((e) => {
                setErrore("Errore durante il recupero delle informazioni richieste");

                console.log(e);
                setFormSchema();
                console.error("ERRORE! ", e)
                //window.location.replace("/errore/404");
            });
    };






    return <React.Fragment><>{loading ? (
        <Spinner />
    ) : (

        <>

            {errore !== "" ? <>
                <div class="container">
                    <div class="alert alert-danger" role="alert">
                        {errore}
                    </div>
                </div>

            </> : <>

                <div className="container">
                    <div className="row ">
                        <div className="col-12">
                            <h1>Quadro complessivo degli indicatori</h1>
                            <div class="table-responsive-xl ">
                                <table class="table table-sm table-bordered ">
                                    <thead class=" neutral-1-bg-a3">
                                        <tr>
                                            <th scope="col" className="larghezza-colonna">Tema</th>
                                            <th scope="col" className="larghezza-colonna">Indicatore</th>
                                            <th scope="col" className="larghezza-colonna">DPSIR</th>
                                            <th scope="col" className="larghezza-colonna">Copertura temporale</th>
                                            <th scope="col" className="larghezza-colonna">Copertura territoriale</th>
                                            <th scope="col" className="larghezza-colonna text-center">Trend</th>
                                            <th scope="col" className="larghezza-colonna text-center">Situazione</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formSchema.listaIndicatori.map((data, index) => {
                                            return <RigaSchedaIndicatore data={data} />;
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div> </>}
        </>
    )}</></React.Fragment>
}
