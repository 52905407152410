export default function getCodtrel() {
    let codtrel = "AAA";

    let url = window.location.href;

    url = url.replace(window.location.protocol + "//", "");
    url = url.replace(window.location.host, "");

    url = url.substring(1);

    //console.info("n: " + url.indexOf("/") + " - " + url);

    if (url.indexOf("/") > 0) {
        codtrel = url.substring(0, url.indexOf("/"));
    } else {
        codtrel = url.substring(0);
    }

    //  console.info(codtrel);

    return codtrel;
}