import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Configurazione from "../config/configurazione";


export default function Layout({ children }) {


    let configurazione = Configurazione();


    return (<>
        <meta charSet="utf-8"></meta>
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no"></meta>
        <title>Relazione sullo stato dell'ambiente - Regione Liguria</title>

        {configurazione.deployContext != "PRODUZIONE" &&
            <div class="github-ribbon nascondi-nella-stampa">
                <span>Modalità {configurazione.deployContext}</span>
            </div>
        }


        <Header />
        {children}
        <Footer />

    </>);
}